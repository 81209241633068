import { objectToFormData } from "src/library/utils";
import { clientWithoutToken, clientWithToken } from "./axios";

/****************************************************************
 * 회원가입
 ****************************************************************/
/**
 * @typedef {Object} PostUser
 * @property {string} username
 * @property {string} email
 * @property {string} password
 * @property {string} privacyPolicyAgreement
 * @property {string} marketingAgreement
 * @property {string} firstName
 * @property {string} lastName
 * @property {string} company
 */
/**
 * 입력한 정보로 회원가입을 요청합니다.
 * @param {PostUser} input
 */
export const postUser = (input) => {
  const payload = {
    username: input.username,
    email: input.email,
    password: input.password,
    privacy_policy_agreement: input.privacyPolicyAgreement,
    marketing_agreement: input.marketingAgreement,
    first_name: input.firstName,
    last_name: input.lastName,
    company: input.company,
  };
  return clientWithoutToken.post(`/user`, payload);
};

/****************************************************************
 * 인증메일요청
 ****************************************************************/
/**
 * @typedef {Object} PostActivation
 * @property {string} email
 */
/**
 * 서버에 이메일 인증을 보낼것을 요청합니다. 회원가입시 서버에서 이메일 인증을 자동으로 보내지만, 수동으로 재요청 할 때 사용합니다.
 * @param {PostActivation} input
 */
export const postActivation = (input) => {
  const payload = {
    email: input.email,
  };
  return clientWithoutToken.post(`/activation`, payload);
};

/****************************************************************
 * 로그인
 ****************************************************************/
/**
 * @typedef {Object} GetToken
 * @property {string} username
 * @property {string} password
 */
/**
 * @typedef {Object} Token
 * @property {string} current_time
 * @property {string} session_id
 * @property {string} user_id
 * @property {string} username
 * @property {string} email
 * @property {string} access_token
 * @property {string} refresh_token
 */
/**
 * 로그인을 요청하고 사용자정보와 토큰을 받아옵니다.
 * @param {GetToken} input
 * @return {Promise<import('axios').AxiosResponse<Token>>}
 */
export const getToken = (input) => {
  const payload = {
    username: input.username,
    password: input.password,
  };
  // 로그인 부분은 fastapi 서버특성상 json이 아닌 form으로 보내야 합니다.
  return clientWithoutToken.post(`/login`, objectToFormData(payload));
};

/****************************************************************
 * 새로운 토큰 얻기
 ****************************************************************/
/**
 * @typedef {Object} PostToken
 * @property {string} accessToken
 * @property {string} refreshToken
 */
/**
 * refreshToken을 사용하여 새로운 토큰들을 얻습니다.
 * @param {PostToken} input
 * @return {Promise<import('axios').AxiosResponse<Token>>}
 */
export const postToken = (input) => {
  const payload = {
    access_token: input.accessToken,
    refresh_token: input.refreshToken,
  };
  return clientWithoutToken.post(`/token`, payload);
};

/****************************************************************
 * 회원정보조회
 ****************************************************************/
/**
 * @typedef {Object} User
 * @property {string} user_id
 * @property {string} username
 * @property {string} email
 * @property {boolean} privacy_policy_agreement
 * @property {boolean} marketing_agreement
 * @property {string} first_name
 * @property {string} last_name
 * @property {string} company
 * @property {number} credit
 * @property {boolean} is_active
 * @property {boolean} is_admin
 * @property {string} created_time
 * @property {string} last_login_time
 * @property {string} current_time
 */
/**
 * 현재 토큰의 유저정보를 서버에서 조회합니다.
 * @returns {Promise<import('axios').AxiosResponse<User>>}
 */
export const getUser = () => clientWithToken.get(`/user`);

/****************************************************************
 * 회원정보수정
 ****************************************************************/
/**
 * @typedef {Object} PutUser
 * @property {boolean} marketingAgreement
 * @property {string} firstName
 * @property {string} lastName
 * @property {string} company
 */
/**
 * 회원정보를 수정하고 변경된 데이터를 받습니다.
 * @param {PutUser} input
 * @returns {Promise<import('axios').AxiosResponse<User>>}
 */
export const putUser = (input) => {
  const payload = {
    marketing_agreement: input.marketingAgreement,
    first_name: input.firstName,
    last_name: input.lastName,
    company: input.company,
  };
  clientWithToken.put(`/user`, payload);
};

/****************************************************************
 * 비밀번호초기화 이메일 발송 요청
 ****************************************************************/
/**
 * @typedef {Object} PostPassword
 * @property {string} email
 */
/**
 * 입력된 이메일 주소에 대한 비밀번호 초기화 메일을 발송할 것을 서버에 요청합니다.
 * @param {PostPassword} input
 */
export const postPassword = (input) => {
  const payload = {
    email: input.email,
  };
  return clientWithoutToken.post(`/pwd`, payload);
};

/****************************************************************
 * 비밀번호변경
 ****************************************************************/
/**
 * @typedef {Object} PutPassword
 * @property {string} password
 */
/**
 * 입력된 이메일 주소에 대한 비밀번호 초기화 메일을 발송할 것을 서버에 요청합니다.
 * @param {PutPassword} input
 */
export const putPassword = (input) => {
  const payload = {
    password: input.password,
  };
  return clientWithToken.put(`/pwd`, payload);
};

/****************************************************************
 * 모델생성
 ****************************************************************/
/**
 * @typedef {Object} PostModel
 * @property {string} modelName
 * @property {string} description
 * @property {"object_detection" | "image_classification" | "image_segmentation" | "semantic_segmentation" | "instance_segmentation" | "panoptic_segmentation" | "other"} task
 * @property {"tensorflow_keras" | "onnx" | "openvino" | "tensorflow_lite" | "tensorrt" | "pytorch | "tensorflow_tao"} framework
 * @property {string} metricUnit
 * @property {number} metricValue
 * @property {number} inputShapeBatch
 * @property {number} inputShapeChannel
 * @property {string} inputShapeDimension
 */
/**
 * @typedef {Object} Model
 * @property {string} original_model_idinput_shape_batch
 * @property {string} original_compression_id
 * @property {string} model_id
 * @property {string} model_name
 * @property {string} description
 * @property {string} created_time
 * @property {string} modified_time
 * @property {"tensorflow_keras" | "onnx" | "openvino" | "tensorflow_lite" | "tensorrt" | "pytorch"} framework
 * @property {"object_detection" | "image_classification" | "image_segmentation"| "semantic_segmentation" | "instance_segmentation" | "panoptic_segmentation" | "other"} task
 * @property {"custom" | "npms"} origin_from
 * @property {Metric} metric
 * @property {Array<InputLayer>} input_layers
 * @property {string} target_device
 * @property {Spec} spec
 * @property {Property} property
 * @property {Status} status
 * @property {Array<Device>} devices
 * @property {Array<Edge>} edges
 * @property {Array<Node>} nodes
 */
/**
 * @typedef {Object} Metric
 * @property {string} metric_unit
 * @property {number} metric_value
 */
/**
 * @typedef {Object} InputLayer
 * @property {number} batch
 * @property {number} channel
 * @property {Array<number>} dimension
 * @property {string} name
 */
/**
 * @typedef {Object} Spec
 * @property {number} model_size
 * @property {number} flops
 * @property {number} trainable_parameters
 * @property {number} non_trainable_parameters
 * @property {number} layer_count
 */
/**
 * @typedef {Object} Property
 * @property {boolean} is_uploaded
 * @property {boolean} is_copied
 * @property {boolean} is_compressed
 * @property {boolean} is_trained
 * @property {boolean} is_deleted
 * @property {boolean} is_downloadable
 * @property {boolean} is_retrainable
 * @property {boolean} is_compressible
 * @property {boolean} is_convertible
 * @property {boolean} is_packageable
 * @property {boolean} is_visible
 */
/**
 * @typedef {Object} Status
 * @property {boolean} is_compressable
 * @property {boolean} is_convertible
 * @property {boolean} is_packageable
 */
/**
 * @typedef {Object} Device
 * @property {string} name
 * @property {number} total_latency
 * @property {Array<{name:string,value:string}>} spec
 * @property {Array<{name:string,value:number}>} performance
 * @property {Array<{name:string,latency:number}>} layers
 */
/**
 * @typedef {Object} Edge
 * @property {number} from
 * @property {number} to
 */
/**
 * @typedef {Object} Node
 * @property {number} id
 * @property {"input"|"default"|"output"} type
 * @property {Object} values
 */
/**
 * 서버에 모델을 새로 생성합니다.
 * @param {PostModel} input
 * @returns {Promise<import('axios').AxiosResponse<Model>}
 */
export const postModel = (input) => {
  const payload = {
    model_name: input.modelName,
    description: input.description,
    file: input.file,
    framework: input.framework,
    task: input.task,
    metric_unit: input.metricUnit,
    metric_value: input.metricValue,
    input_layers: JSON.stringify([
      {
        batch: input.inputShapeBatch,
        channel: input.inputShapeChannel,
        dimension: input.inputShapeDimension,
      },
    ]),
  };
  return clientWithToken.post(`/models`, objectToFormData(payload));
};

/****************************************************************
 * 모델목록조회
 ****************************************************************/
/**
 * 현재 사용자의 모든 모델 목록을 조회합니다.
 * @returns {Promise<import('axios').AxiosResponse<Model[]>>}
 */
export const getModels = () => clientWithToken.get(`/models`);

/****************************************************************
 * 모델상세조회
 ****************************************************************/
/**
 * @typedef {Object} GetModel
 * @property {string} modelId
 */
/**
 * 특정 모델의 상세를 조회합니다.
 * @param {GetModel} input
 * @returns {Promise<import('axios').AxiosResponse<Model>>}
 */
export const getModel = (input) =>
  clientWithToken.get(`/models/${input.modelId}`);

/****************************************************************
 * 모델업로드 url 얻기
 ****************************************************************/
// /**
//  * @typedef {Object} ModelURL
//  * @param {string} url
//  * @returns
//  */
// /**
//  * @typedef {Object} GetModelUploadURL
//  * @property {string} modelId
//  */
// /**
//  * 특정 모델의 업로드 url을 얻습니다.
//  * @param {GetModelUploadURL} input
//  * @returns {Promise<import('axios').AxiosResponse<ModelURL>>}
//  */
// export const getModelUploadURL = (input) => {
//   return client.get(`/models/${input.modelId}/upload`);
// };

/****************************************************************
 * 모델업로드
 ****************************************************************/
// /**
//  * @typedef {Object} UploadModelResult
//  * @returns
//  */
// /**
//  * @typedef {Object} UploadModel
//  * @property {File} file
//  * @property {string} preSignedURL
//  */
// /**
//  * presigned url에 파일을 업로드 합니다.
//  * @param {UploadModel} input
//  * @returns {Promise<import('axios').AxiosResponse<UploadModelResult>>}
//  */
// export const uploadModel = (input) => {
//   const client = axios.create();
//   return client.put(`${input.preSignedURL}`, input.file, {
//     headers: {
//       "Content-Type": "application/octet-stream",
//     },
//   });
// };

/****************************************************************
 * 모델업로드 완료 처리
 ****************************************************************/
// /**
//  * @typedef {Object} PutModelUploadURL
//  * @property {string} modelId
//  */
// /**
//  * 특정 모델의 업로드를 완료처리 합니다.
//  * @param {GetModelUploadURL} input
//  * @returns {Promise<import('axios').AxiosResponse<ModelURL>>}
//  */
// export const putModelUploadURL = (input) =>
//   client.put(`/models/${input.modelId}/upload`);

/****************************************************************
 * 모델다운로드 url 얻기
 ****************************************************************/
/**
 * @typedef {Object} PostModelDownloadURL
 * @property {string} modelId
 */
/**
 * 특정 모델의 다운로드 url을 얻습니다.
 * @param {PostModelDownloadURL} input
 * @returns {Promise<import('axios').AxiosResponse<ModelURL>>}
 */
export const postModelDownloadURL = (input) =>
  clientWithToken.post(`/models/${input.modelId}/download`);

/****************************************************************
 * 모델수정
 ****************************************************************/
/**
 * @typedef {Object} PutModel
 * @property {string} modelId
 * @property {string} modelName
 * @property {string} description
 * @property {string} metricUnit
 * @property {number} metricValue
 * @property {number} originalMetricValue
 */
/**
 * 특정 모델의 상세를 수정합니다.
 * @param {GetModel} input
 * @returns {Promise<import('axios').AxiosResponse<Model>>}
 */
export const putModel = (input) => {
  const payload = {
    model_name: input.modelName,
    description: input.description,
    metric_unit: input.metricUnit,
    metric_value: input.metricValue,
    original_metric_value: input.originalMetricValue,
  };
  return clientWithToken.put(`/models/${input.modelId}`, payload);
};

/****************************************************************
 * 모델삭제
 ****************************************************************/
/**
 * @typedef {Object} DeleteModel
 * @property {string} modelId
 */
/**
 * 서버에 입력된 아이디의 모델을 삭제요청 합니다.
 * @param {DeleteModel} input
 * @returns
 */
export const deleteModel = (input) =>
  clientWithToken.delete(`/models/${input.modelId}`);

/****************************************************************
 * 데이터셋 업로드
 ****************************************************************/
/**
 * @typedef {Object} PostDataset
 * @property {File} file
 * @property {string} modelId
 */
/**
 * 해당 모델의 compression을 위한 데이터셋을 업로드합니다. 특정 compression을 위해서 데이터셋은 사용됩니다.
 * @param {PostDataset} input
 */
export const postDataset = (input) => {
  const payload = {
    file: input.file,
  };
  return clientWithToken.post(
    `/models/${input.modelId}/datasets`,
    objectToFormData(payload)
  );
};

/****************************************************************
 * 입력 추천값 생성
 ****************************************************************/
/**
 * @typedef {Object} PostRecommendation
 * @property {string} modelId
 * @property {string} compressionId
 * @property {RecommendationMethod} recommendationMethod
 * @property {number} recommendationRatio
 */
/**
 * @typedef {Object} Recommendation
 * @property {Array<RecommendationLayer>} recommendation_layers
 */
/**
 * @typedef {Object} RecommendationLayer
 * @property {string} name
 * @property {Array<number>} values
 */
/**
 * 지정한 옵션으로 지정한 모델에 대한 추천값을 얻습니다. VBMF는 [inRank, outRank]의 형태로 결과를 얻으며, global norm 계열은 ratio의 형태로 결과를 얻습니다.
 * @param {PostRecommendation} input
 * @returns {Promise<import('axios').AxiosResponse<Recommendation>>}
 */
export const postRecommendation = (input) => {
  const payload = {
    compression_id: input.compressionId,
    recommendation_method: input.recommendationMethod,
    recommendation_ratio: input.recommendationRatio,
  };
  return clientWithToken.post(
    `/models/${input.modelId}/recommendation`,
    payload
  );
};

/****************************************************************
 * compression 생성
 ****************************************************************/
/**
 * @typedef {Object} PostCompression
 * @property {string} modelId
 * @property {string} modelName
 * @property {string} description
 * @property {"FD_TK"|"FD_SVD"|"FD_CP"|"PR_L2"|"PR_GM"|"PR_NN"|"PR_ID"} compressionMethod
 */
/**
 * @typedef {Object} Compression
 * @property {string} new_model_id
 * @property {string} compression_id
 * @property {"FD_TK"|"FD_SVD"|"FD_CP"|"PR_L2"|"PR_GM"|"PR_NN"|"PR_ID"} compression_method
 * @property {Options} options
 * @property {Array<AvailableLayer>} available_layers
 */
/**
 * @typedef {Object} Options
 * @property {"intersection" | "average" | "union"} policy
 */
/**
 * @typedef {Object} AvailableLayer
 * @property {boolean} use
 * @property {string} name
 * @property {Array<number>} values
 * @property {Array<number>} channels
 * @property {Array<{device_name: string, value: number}>} latency
 */
/**
 * 해당 모델에 대한 compression이 가능한 레이어 목록을 서버에서 얻습니다.
 * @param {PostCompression} input
 * @returns {Promise<import('axios').AxiosResponse<Compression>>}
 */
export const postCompression = (input) => {
  const payload = {
    model_id: input.modelId,
    model_name: input.modelName,
    description: input.description,
    compression_method: input.compressionMethod,
  };
  return clientWithToken.post(`/compressions`, payload);
};

/****************************************************************
 * Compression 정보 얻기
 ****************************************************************/
/**
 * @typedef {Object} GetCompression
 * @property {string} compressionId
 */
/**
 * compression 정보를 얻습니다.
 * @param {GetCompression} input
 * @returns {Promise<import('axios').AxiosResponse<Compression>>}
 */
export const getCompression = (input) =>
  clientWithToken.get(`compressions/${input.compressionId}`);

/****************************************************************
 * compression 진행
 ****************************************************************/
/**
 * @typedef {Object} Layer
 * @property {boolean} use
 * @property {string} name
 * @property {Array<number>} values
 */
/**
 * @typedef {Object} PutCompression
 * @property {string} compressionId
 * @property {Layer[]} layers
 * @property {Option} options
 */
/**
 * 서버에 compression을 진행할 것을 요청합니다.
 * @param {PutCompression} input
 * @return {Promise<import('axios').AxiosResponse<Compression>>}
 */
export const putCompression = (input) => {
  const payload = {
    layers: input.layers,
    options: input.options,
  };
  return clientWithToken.put(`/compressions/${input.compressionId}`, payload);
};

/****************************************************************
 * auto compress
 ****************************************************************/
/**
 * @typedef {Object} postAutoCompress
 * @property {string} modelId
 * @property {string} modelName
 * @property {string} description
 * @property {number} recommendationRatio
 */
/**
 * 모델의 벤치마크 결과를 받아옵니다. 레이어별 latency가 받아집니다.
 * @param {postAutoCompress} input
 * @returns {Promise<import('axios').AxiosResponse<Model>>}
 */
export const postAutoCompress = (input) => {
  const payload = {
    model_name: input.modelName,
    description: input.description,
    recommendation_ratio: input.recommendationRatio,
  };
  return clientWithToken.post(
    `/models/${input.modelId}/auto_compress`,
    payload
  );
};

/****************************************************************
 * models page
 ****************************************************************/
// /**
//  * @typedef {Object} getModels
//  * @property {number} offset
//  * @property {number} limit
//  * @property {string} query
//  */
// /**
//  * @typedef {Object} ChildModel
//  * @property {Array<Model>} children
//  *
//  * @typedef {Model & ChildModel} ParentModel
//  */
// /**
//  * 부모모델과 자식모델로 구성된 models 데이터를 불러옵니다.
//  * @param {getModels} input
//  * @returns {Promise<import('axios').AxiosResponse<ParentModel[]>>}
//  */
// export const getModelsPage = (input) => {
//   const payload = {
//     offset: input.offset,
//     limit: input.limit,
//     query: input.query,
//   };
//   return client.get(
//     `/models/show_table?offset=${payload.offset}&limit=${payload.limit}&query=${payload.query}`
//   );
// };

/****************************************************************
 * parent models
 ****************************************************************/
/**
 * 모든 부모 모델을 불러옵니다.
 * @returns {Promise<import('axios').AxiosResponse<Model[]>>}
 */
export const getParentsModels = () => {
  return clientWithToken.get(`/models/parents`);
};

/****************************************************************
 * children models
 ****************************************************************/
/**
 * @typedef {Object} getChildrenModels
 * @property {string} modelId
 */
/**
 * 특정 모델의 자식 모델을 불러옵니다.
 * @param {getChildrenModels} input
 * @returns {Promise<import('axios').AxiosResponse<Model[]>>}
 */
export const getChildrenModels = (input) => {
  return clientWithToken.get(`/models/${input.modelId}/children`);
};
