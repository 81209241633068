const defaultFlags = {
  SignUp: {
    prod: true,
    staging: true,
    dev: true,
    lgcns: false,
  }
}

export const flag = (featureFlag) => {
  const processEnv = process.env.REACT_APP_ENVIRONMENT || process.env.ENVIRONMENT || process.env.NODE_ENV;

  let env =  '';

  switch (processEnv) {
    case 'production':
    case 'staging':
    case 'development':
    case 'local':
    case 'lgcns':
      env = processEnv;
      break;
    default:
      throw new Error(`Unexpected environment: ${processEnv}`);
  }

  const isEnabled = defaultFlags[featureFlag][env];

  return isEnabled;
};