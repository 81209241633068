import "core-js";
import "react-app-polyfill/ie11"; // For IE 11 support
import "react-app-polyfill/stable";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactFlowProvider } from "react-flow-renderer";
import CookieConsent from "./components/CookieConsent";

const queryClient = new QueryClient();
queryClient.setDefaultOptions({
  queries: {
    retry: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  },
});

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <ReactFlowProvider>
      <App />
      <CookieConsent />
    </ReactFlowProvider>
    {/* <ReactQueryDevtools initialIsOpen={false} position="bottom-right" /> */}
  </QueryClientProvider>,
  document.getElementById("root")
);
