import PasswordIcon from "@mui/icons-material/Password";
import PersonIcon from "@mui/icons-material/Person";
import { useState } from "react";
import { useNavigate } from "react-router";
import { flag } from "src/constants/featureFlags";
import { getToken, postActivation } from "src/library/apis";
import {
  applyAccessToken,
  setAccessToken,
  setRefreshToken
} from "src/library/token";
import IconInput from "src/pages/Login/component/IconInput";
import Button from "./component/Button";
import LinkButton from "./component/LinkButton";
import Title from "./component/Title";

const Login = ({ changeSite }) => {
  const navigate = useNavigate();
  const [loginInput, setLoginInput] = useState({
    email: "",
    password: "",
    loading: false,
  });
  const handleChangeInput = (e) => {
    setLoginInput({
      ...loginInput,
      [e.target.name]: e.target.value,
    });
  };
  const checkInputValue = () => {
    if (loginInput.email === "") {
      alert("Please enter your email");
      return false;
    }
    if (loginInput.password === "") {
      alert("Please enter your password");
      return false;
    }
    return true;
  };
  const handleClickLogin = async () => {
    // validation
    if (!checkInputValue()) {
      return;
    }
    // ajax
    setLoginInput({
      ...loginInput,
      loading: true,
    });
    try {
      const response = await getToken({
        username: loginInput.email,
        password: loginInput.password,
      });
      setAccessToken(response.data.access_token);
      setRefreshToken(response.data.refresh_token);
      applyAccessToken(response.data.access_token);
      setLoginInput({
        ...loginInput,
        loading: false,
      });
      navigate("/models");
    } catch (error) {
      setLoginInput({
        ...loginInput,
        loading: false,
      });
      if (error.response) {
        if (error.response.status === 400) {
          alert("Please enter a valid email or password.");
        } else if (error.response.status === 403) {
          if (
            window.confirm(
              "Your email has not yet been validated.\nIf you haven't received an email yet, please check the spam mailbox or promotional mailbox.\nIf you want to resend the validation email, you can resend it.\nDo you want to resend the validation email?"
            )
          ) {
            await handleRequestValidationEmail();
          }
        }
      }
    }
  };
  const handleRequestValidationEmail = async () => {
    try {
      await postActivation({
        email: loginInput.email,
      });
      alert("Validation email has been sent again.");
    } catch (error) {
    } finally {
    }
  };
  const handlePressEnter = (e) => {
    if (e.key === "Enter") {
      handleClickLogin();
    }
  };
  const handleClickSignup = () => {
    // changeSite("signup");
    window.location.assign(`${process.env.REACT_APP_PORTAL_URL}/signup`);
  };
  const handleClickNewPassword = () => {
    // changeSite("newpassword");
    window.location.assign(`${process.env.REACT_APP_PORTAL_URL}/new-password`);
  };
  return (
    <div>
      <Title title="Sign in" />
      <IconInput
        icon={<PersonIcon />}
        type="email"
        name="email"
        placeholder="Email"
        disabled={loginInput.loading}
        onChange={handleChangeInput}
        onKeyPress={handlePressEnter}
        data-cy="signin-email-input"
      />
      <IconInput
        icon={<PasswordIcon />}
        type="password"
        name="password"
        placeholder="Password"
        disabled={loginInput.loading}
        onChange={handleChangeInput}
        onKeyPress={handlePressEnter}
        data-cy="signin-password-input"
      />
      <div className="flex justify-end">
        <LinkButton
          disabled={loginInput.loading}
          onClick={handleClickNewPassword}
        >
          Did you forget your password?
        </LinkButton>
      </div>
      <div className="flex justify-center">
        <Button
          disabled={loginInput.loading}
          onClick={handleClickLogin}
          data-cy="signin-signin-button"
        >
          Sign in
        </Button>
      </div>
      <div className="flex justify-center">
        {flag("SignUp") && (
          <LinkButton disabled={loginInput.loading} onClick={handleClickSignup}>
            Sign Up
          </LinkButton>
        )}
      </div>
    </div>
  );
};

export default Login;
