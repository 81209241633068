import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import ApartmentIcon from "@mui/icons-material/Apartment";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PasswordIcon from "@mui/icons-material/Password";
import PersonIcon from "@mui/icons-material/Person";
import { useState } from "react";
import law from "src/constants/law";
import { postUser } from "src/library/apis";
import { emailRegExp } from "src/library/regexp";
import Button from "./component/Button";
import IconInput from "./component/IconInput";
import LinkButton from "./component/LinkButton";
import Title from "./component/Title";

const SignUp = ({ changeSite }) => {
  const [signUpInput, setSignUpInput] = useState({
    email: "",
    password1: "",
    password2: "",
    privacyPolicyAgreement: false,
    marketingAgreement: false,
    firstName: "",
    lastName: "",
    company: "",
    loading: false,
  });
  const handleChangeInput = (e) => {
    if (e.target.type === "checkbox") {
      setSignUpInput({
        ...signUpInput,
        [e.target.name]: e.target.checked,
      });
    } else {
      setSignUpInput({
        ...signUpInput,
        [e.target.name]: e.target.value,
      });
    }
  };
  const checkInputValue = (input) => {
    // 개인정보 사용동의 점검
    if (input.agreementPrivacy === false) {
      alert("Please agree a privacy policy to proceed sign up.");
      return false;
    }
    // 이메일 주소 입력 점검
    if (input.email === "") {
      alert("Please enter a email address.");
      return false;
    }
    // 이메일 주소 형식 점검
    if (!input.email.match(emailRegExp)) {
      alert("Please enter a valid email.");
      return false;
    }
    // 비밀번호 입력 점검
    if (input.password1 === "" || input.password2 === "") {
      alert("Please enter a password.");
      return false;
    }
    // 비밀번호 일치 점검
    if (input.password1 !== input.password2) {
      alert("Please enter a same password.");
      return false;
    }
    // first name 입력 점검
    if (input.firstName === "") {
      alert("Please enter a first name.");
      return false;
    }
    // last name 입력 점검
    if (input.lastName === "") {
      alert("Please enter a last name.");
      return false;
    }
    // company 입력 점검
    if (input.company === "") {
      alert("Please enter a company.");
      return false;
    }
    return true;
  };
  const handleClickSignUp = async () => {
    if (!checkInputValue(signUpInput)) {
      return;
    }
    try {
      setSignUpInput({ ...signUpInput, loading: false });
      await postUser({
        username: signUpInput.email,
        email: signUpInput.email,
        password: signUpInput.password1,
        privacyPolicyAgreement: signUpInput.privacyPolicyAgreement,
        marketingAgreement: signUpInput.marketingAgreement,
        firstName: signUpInput.firstName,
        lastName: signUpInput.lastName,
        company: signUpInput.company,
      });
      setSignUpInput({ ...signUpInput, loading: false });
      alert(
        "Done!\nPlease check your mail to validate this account.\nIf you can't find an email in your mailbox, please check your spam mailbox or promotional mailbox."
      );
      changeSite("login");
    } catch (error) {
      setSignUpInput({ ...signUpInput, loading: false });
      if (error.response) {
        if (error.response.status === 403) {
          alert("This email is already signed up.");
        }
      }
    }
  };
  const handleClickPrivacy = () => {
    window.open("https://www.netspresso.ai/privacy-policy");
  };
  const handleClickBack = () => {
    changeSite("login");
  };
  return (
    <div>
      <div>
        <button onClick={handleClickBack} className="text-white">
          <ArrowBackIcon /> Back to Login
        </button>
      </div>
      <br />
      <Title title="Sign Up" subtitle="Create your account">
        Sign Up
      </Title>
      <div className="h-80 whitespace-pre-line overflow-auto scrollbar-hide text-white border-2 p-2">
        {law}
      </div>
      <div className="flex justify-end mb-2">
        <LinkButton onClick={handleClickPrivacy}>
          Show privacy policy in a new window
        </LinkButton>
      </div>
      <label className="text-white flex">
        <input
          name="privacyPolicyAgreement"
          type="checkbox"
          disabled={signUpInput.loading}
          onChange={handleChangeInput}
          className="scale-125 mt-1 mr-2 peer"
        />
        <span className="peer-disabled:text-gray-400">
          I Agree with the privacy policy.
        </span>
      </label>
      <label className="text-white flex">
        <input
          name="marketingAgreement"
          type="checkbox"
          disabled={signUpInput.loading}
          onChange={handleChangeInput}
          className="scale-125 mt-1 mr-2 peer"
        />
        <span className="peer-disabled:text-gray-400">
          I want to recieve communications or be contacted by Nota about related
          technology. (optional)
        </span>
      </label>
      <IconInput
        icon={<AlternateEmailIcon />}
        type="email"
        name="email"
        placeholder="Email"
        disabled={signUpInput.loading}
        onChange={handleChangeInput}
      />
      <IconInput
        icon={<PasswordIcon />}
        type="password"
        name="password1"
        placeholder="Password"
        disabled={signUpInput.loading}
        onChange={handleChangeInput}
      />
      <IconInput
        icon={<PasswordIcon />}
        type="password"
        name="password2"
        placeholder="Repeat Password"
        disabled={signUpInput.loading}
        onChange={handleChangeInput}
      />
      <IconInput
        icon={<PersonIcon />}
        type="text"
        name="firstName"
        placeholder="First Name"
        disabled={signUpInput.loading}
        onChange={handleChangeInput}
      />
      <IconInput
        icon={<PersonIcon />}
        type="text"
        name="lastName"
        placeholder="Last Name"
        disabled={signUpInput.loading}
        onChange={handleChangeInput}
      />
      <IconInput
        icon={<ApartmentIcon />}
        type="text"
        name="company"
        placeholder="Company"
        disabled={signUpInput.loading}
        onChange={handleChangeInput}
      />
      <div className="flex justify-center">
        <Button disabled={signUpInput.loading} onClick={handleClickSignUp}>
          Sign Up
        </Button>
      </div>
    </div>
  );
};

export default SignUp;
